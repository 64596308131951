import React from "react";
import { Link as GLink } from "gatsby";

const Link = ({ title, href, child = [], isChild = false }) => {
  return (
    <li className="navlink relative bg-darkblue-800">
      <GLink
        to={href}
        className={`flex items-center h-full w-full py-4 whitespace-nowrap ${
          isChild
            ? "px-12 lg:px-4"
            : child.length > 0
            ? "px-8 lg:px-12"
            : "lg:px-6 px-8"
        }`}
        // activeClassName="underline underline-offset-4 decoration-4 decoration-violet-500"
      >
        {title}
      </GLink>
      {child.length > 0 && (
        <ul className="absolute -mt-1 w-full left-0 shadow-lg shadow-violet-500/50 z-10 bg-darkblue-800 text-white">
          {child.map((link) => (
            <Link
              key={link.href}
              title={link.title}
              href={link.href}
              child={link.child}
              isChild={true}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default Link;
