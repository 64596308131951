import React, { useState } from "react";
import Link from "./link";

import icon from "../../images/icon.png";

import { AiOutlineMenu } from "react-icons/ai";
import { MdAnnouncement, MdClose } from "react-icons/md";
const navItems = [
  {
    title: "主頁",
    href: "#home",
  },
  {
    title: "簡介",
    href: "#about",
  },
  {
    title: "練習",
    href: "#modes",
  },
  {
    title: "特色",
    href: "#features",
  },
  {
    title: "價錢",
    href: "#pricing",
  },
  {
    title: "Q&A",
    href: "#qa",
  },
  {
    title: "評價",
    href: "#testimonial",
  },
  {
    title: "下載",
    href: "#download",
  },
  {
    title: "其他",
    href: "#",
    child: [
      {
        title: "最新消息",
        href: "https://docs.cangjie.app/blog/",
      },
      {
        title: "支援",
        href: "https://help.cangjie.app/",
      },
      {
        title: "指南",
        href: "https://docs.cangjie.app/",
      },
      {
        title: "常見問題",
        href: "https://docs.cangjie.app/docs/info/qa",
      },
      {
        title: "私隱政策",
        href: "https://docs.cangjie.app/docs/info/privacy-policy",
      },
      {
        title: "更新日誌",
        href: "https://docs.cangjie.app/docs/info/changelogs",
      },
    ],
  },
];

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showAnnouncement, setShowAnnouncement] = useState(false);

  return (
    <div className="flex flex-col fixed top-0 left-0 right-0 z-50">
      {showAnnouncement && (
        <div className="bg-amber-200 text-amber-800 text-center px-4 py-4 gap-4 lg:py-2 flex justify-between items-center">
          <div>
            <MdAnnouncement size={24} />
          </div>
          <p>
            [2024-09-23] Google Play
            商店的Android應用目前暫停下載及購買「付費完整版」，預計於一星期內恢復
          </p>
          <button
            className="self-start lg:self-auto"
            onClick={() => setShowAnnouncement(false)}
          >
            <MdClose size={24} />
          </button>
        </div>
      )}

      <header
        className={` bg-darkblue-800 text-white flex flex-col lg:flex-row lg:items-center shadow-md lg:h-20 ${
          showMobileMenu ? "h-screen" : ""
        } `}
      >
        <div className="flex w-full items-center justify-between self-center">
          <div className="flex items-center">
            <img className="h-16 w-16" src={icon} />
            <p className="text-xl">倉頡/速成練習工具</p>
          </div>
          <AiOutlineMenu
            className="lg:hidden mr-4 cursor-pointer"
            size={32}
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          />
        </div>
        <ul
          className={`${
            showMobileMenu ? "flex" : "hidden"
          } flex-grow lg:flex-grow-0 flex-col w-full bg-darkblue-800 lg:w-auto lg:flex lg:flex-row overflow-y-auto lg:overflow-y-visible`}
        >
          {navItems.map((item) => (
            <Link
              title={item.title}
              href={item.href}
              child={item?.child || []}
              key={item.href}
            />
          ))}
        </ul>
      </header>
    </div>
  );
};

export default Header;
