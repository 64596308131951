import * as React from "react";
import { Helmet } from "react-helmet";
import "../styles/global.css";
import Header from "../components/header";
import Home from "./sections/home";

import About from "./sections/about";
import Modes from "./sections/modes";
import Features from "./sections/features";
import Pricing from "./sections/pricing";
import QA from "./sections/qa";
import Testimonial from "./sections/testimonial";
import Download from "./sections/download";
import Footer from "../components/footer";

const IndexPage = () => {
  return (
    <div>
      <Helmet>
        <title>倉頡/速成練習工具</title>
        <meta name="title" content="倉頡/速成練習工具" />
        <meta
          name="description"
          content="倉頡/速成練習工具內含3000+常 /用中文繁體字，並會定期更新添加，可以透過內建的倉頡鍵盤，輸入對應文字的倉頡碼，練習同時加以思考、累積經驗。"
        />
        <meta
          name="keywords"
          content="倉頡, 倉頡練習, 速成, 速成練習, 學打字, 練習工具, 輸入法"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="author" content="Tung To Technology 同途科技" />
      </Helmet>
      <Header />
      <div>
        <Home />
        <About />
        <Modes />
        <Features />
        <Pricing />
        <QA />
        <Testimonial />
        <Download />
      </div>
      <Footer />
    </div>
  );
};

export default IndexPage;
