import React from "react";
import Section from "../../components/section";

import bg0 from "../../images/background-0.png";
import bgD0 from "../../images/background-deco-0.svg";
import appstore from "../../images/appstore.svg";
import googlePlay from "../../images/google-play.png";
import appGallery from "../../images/app-gallery.png";

const Home = () => {
  return (
    <Section backgrounds={{ lg: [bg0, bgD0], sm: [bgD0] }} id="home">
      <div className="flex flex-col justify-center items-center w-full h-full">
        <p className="text-white text-6xl mb-4 text-center">
          倉頡/速成練習工具
        </p>
        <p className="text-white text-xl mb-4 text-center">
          熟能生巧，一生受用
        </p>
        <div className="flex flex-col md:flex-row justify-center items-center">
          <a href="https://play.google.com/store/apps/details?id=com.opensertk.cangjie_practice_ss_directload">
            <img
              className="market-badge"
              alt="立即下載 Google Play"
              src={googlePlay}
            />
          </a>
          <a href="https://apps.apple.com/hk/app/%E5%80%89%E9%A0%A1-%E9%80%9F%E6%88%90%E7%B7%B4%E7%BF%92%E5%B7%A5%E5%85%B7/id1600176379">
            <img
              className="market-badge"
              alt="立即下載 Appstore"
              src={appstore}
            />
          </a>
          <a href="https://appgallery.huawei.com/app/C102894033">
            <img
              className="market-badge"
              alt="立即下載 App Gallery"
              src={appGallery}
            />
          </a>
        </div>
      </div>
    </Section>
  );
};

export default Home;
